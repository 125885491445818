import { Capacitor, Plugins } from "@capacitor/core";
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import { calendar, home, settings, sync } from "ionicons/icons";
import React from "react";
import "react-dates/initialize";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import styled from "styled-components";
import { UNSET_ERROR } from "./actions/types";
import configureStore from "./store";

import { IAssetManager } from "@rpforms/shared/build";
import { AssetManager } from "./AssetManager";
import { StorageDialog } from "./components/StorageDialog";
import db from "./db";
import "./theme/variables.css";
import { Login } from "./pages/Login";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { AuthInitializer } from "./components/auth/AuthInitializer";
import routes from "./routes";

const { Keyboard } = Plugins;

export const { store, persistor } = configureStore(undefined);

declare global {
    // tslint:disable-next-line:interface-name
    interface Window {
        OPEN_SUBMITTED: boolean;
        PRELOAD: boolean;
        ONE_BY_ONE: boolean;
        EDIT_MODE: boolean;
        SKIP_SYNC_STORAGE: boolean;
        IGNORE_SETTINGS_WARNING: boolean;
        AssetManager: IAssetManager;
    }
}

if (Capacitor.platform !== "web") {
    Keyboard.setAccessoryBarVisible({ isVisible: false });
}

window.addEventListener("keyboardDidShow", (e) => {
    setTimeout(() => {
        (document.activeElement as any).scrollIntoViewIfNeeded();
    }, 100);
});

window.AssetManager = new AssetManager();
window.IGNORE_SETTINGS_WARNING = false;
window.SKIP_SYNC_STORAGE = localStorage.syncStorage === "true";
window.OPEN_SUBMITTED = localStorage.openSubmitted === "true";

const ErrorToast = styled(IonToast)`
    --background: rgba(255, 65, 65, 0.4);
`;

const ErrorDialogs = () => {
    const dispatch = useDispatch();
    const error = useSelector<any, any>((state) => state.errors);
    const dismissError = () => {
        dispatch({
            type: UNSET_ERROR,
        });
    };

    return (
        <>
            {error.message && (
                <ErrorToast
                    isOpen={true}
                    onDidDismiss={dismissError}
                    message={error.message}
                    duration={2000}
                    showCloseButton={true}
                />
            )}
        </>
    );
};

Sentry.init({
    dsn: "https://09d7046d326c49349bdc650c0db0aa35@o791122.ingest.sentry.io/4504094669078528",
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                "https://api.rpforms.kega.network",
                "https://api.staging.rpforms.kega.network",
            ],
        }),
    ],

    release: "rp-app@" + process.env.REACT_APP_COMMIT,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 0.1,
});

(window as any).PRELOAD = true;
(window as any).EDIT_MODE = true; // TODO: remove globals

type RouteConfig = {
    path: string;
    exact: boolean;
    component?: React.ComponentType<any>;
    render?: () => JSX.Element;
};

const CustomRoute: React.FC<RouteConfig> = ({ component: Component, render, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                Component ? <Component {...props} /> : render ? render() : null
            }
        />
    );
};

const App: React.FunctionComponent = () => {
    (window as any).db = db;
    Sentry.setUser({ username: "iPad", id: `ipad-${localStorage.deviceID}` });

    const isWeb = Capacitor.platform === "web";
    const isDevelopment = process.env.NODE_ENV === "development";
    const isTesting = process.env.NODE_ENV === "test";

    const requireAuth = isWeb && !isDevelopment && !isTesting;

    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Provider store={store}>
                            <AuthInitializer>
                                <ErrorDialogs />
                                <StorageDialog />
                                {requireAuth ? (
                                    <>
                                        <Route path="/login" component={Login} exact={true} />
                                        {routes.map((route) => (
                                            <ProtectedRoute key={route.path} {...route} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {routes.map((route) => (
                                            <CustomRoute key={route.path} {...route} />
                                        ))}
                                    </>
                                )}
                            </AuthInitializer>
                        </Provider>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="calendarevents" href="/calendarevents">
                            <IonIcon icon={calendar} />
                            <IonLabel>Kalenderevents</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="entities" href="/entities">
                            <IonIcon icon={home} />
                            <IonLabel>Objekte</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="sync" href="/sync">
                            <IonIcon icon={sync} />
                            <IonLabel>Synchronisieren</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="settings" href="/settings">
                            <IonIcon icon={settings} />
                            <IonLabel>Einstellungen</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
