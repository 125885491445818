import { Calendar } from "react-big-calendar";
import { Redirect } from "react-router";
import CalendarEventDetails from "./pages/CalendarEventDetails";
import CalendarEvents from "./pages/CalendarEvents";
import Entities from "./pages/Entities";
import EntityDetails from "./pages/EntityDetails";
import Settings from "./pages/Settings";
import StorageSettings from "./pages/Storage";
import Sync from "./pages/Sync";
import TaskDetails from "./pages/TaskDetails";
import React from "react";

export const routes = [
    {
        path: "/:tab(entities)/entity/:entity_id",
        component: EntityDetails,
        exact: true,
    },
    {
        path: "/:tab(settings)/storage",
        component: StorageSettings,
        exact: true,
    },
    {
        path: "/:tab(entities)/entity/:entity_id/:task_id",
        component: TaskDetails,
        exact: true,
    },
    {
        path: "/:tab(calendarevents)/calendar",
        component: Calendar,
        exact: true,
    },
    {
        path: "/:tab(calendarevents)/event/:event_id",
        component: CalendarEventDetails,
        exact: true,
    },
    {
        path: "/:tab(entities)",
        component: Entities,
        exact: true,
    },
    {
        path: "/:tab(calendarevents)",
        component: CalendarEvents,
        exact: true,
    },
    {
        path: "/:tab(sync)",
        component: Sync,
        exact: true,
    },
    {
        path: "/:tab(settings)",
        component: Settings,
        exact: true,
    },
    {
        path: "/",
        render: () => <Redirect to="/entities" />,
        exact: true,
    },
];

export default routes;
