import { AUTH_USER, UNAUTH_USER } from "@rpforms/shared/build/actions/types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const AuthInitializer = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (!storedUser) return;
        try {
            const parsedUser = JSON.parse(storedUser);
            if (parsedUser.exp && parsedUser.exp >= Date.now()) {
                dispatch({ type: AUTH_USER });
            } else {
                dispatch({ type: UNAUTH_USER });
                localStorage.removeItem("user");
            }
        } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            dispatch({ type: UNAUTH_USER });
            localStorage.removeItem("user");
        }
    }, []);

    return children;
};
