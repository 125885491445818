import { Field } from "@rpforms/web/src/components/Field";
import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";
import { Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { ColumnField } from "./ColumnField";

export class GroupField extends BaseField {
    public label = "Gruppe";
    public icon = "square-o";
    public fieldType = "Gruppe";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Width];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className={"p-3"}>
                    <label>Name</label>
                    <input
                        type="text"
                        name={field.inputName("label")}
                        className="form-control"
                        defaultValue={field.label}
                        ref={register}
                    />
                    <br />
                    <div>
                        {field.subfields ? (
                            field.subfields.map((subfield, index) => {
                                return (
                                    <subfield.EditComponent
                                        designer={designer}
                                        register={register}
                                        key={subfield.hashCode + "_" + index}
                                        schema={schema}
                                        field={subfield}
                                        entry={null}
                                    />
                                );
                            })
                        ) : (
                            <span />
                        )}
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.name,
        variables = {},
        saveState = {},
        readonly = false,
    }) {
        const getInputComponentProps = (subfield, index, rawValue) => ({
            ...subfield,
            readonly,
            layout,
            key: Math.random(),
            register,
            name: subfield.hashCode,
            schema,
            field: subfield,
            entry,
            variables,
            saveState,
            rawValue,
        });

        const getWrapComponentProps = (subfield, index) => ({
            ...subfield,
            layout,
            key: `${subfield.hashCode}_${index}_${Math.random()}`,
            schema,
            field: subfield,
            entry,
        });

        return (
            <>
                {field.configureInput(
                    <div data-id={field.label} className="d-flex d-flex-inner">
                        {field.subfields ? (
                            field.subfields.reduce((acc, subfield, index) => {
                                const isColumn = subfield instanceof ColumnField;
                                const rawValue = saveState[subfield.hashCode];

                                const wrappedInput = (
                                    <subfield.WrapComponent
                                        {...getWrapComponentProps(subfield, index)}
                                        component={
                                            <subfield.InputComponent
                                                {...getInputComponentProps(
                                                    subfield,
                                                    index,
                                                    rawValue
                                                )}
                                            />
                                        }
                                    />
                                );

                                // Handle column layout logic
                                if (isColumn) {
                                    const lastElement = acc[acc.length - 1];
                                    const isLastElementColumnGroup = lastElement?.props?.className?.includes(
                                        "column-group"
                                    );

                                    if (!isLastElementColumnGroup) {
                                        // Create new column group
                                        acc.push(
                                            <div
                                                key={`col-group-${index}`}
                                                className="column-group"
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    pageBreakInside: "avoid",
                                                    width: "100%",
                                                }}
                                            >
                                                {wrappedInput}
                                            </div>
                                        );
                                    } else {
                                        // Add to existing column group
                                        const existingChildren = Array.isArray(
                                            lastElement.props.children
                                        )
                                            ? lastElement.props.children
                                            : [lastElement.props.children];

                                        acc[acc.length - 1] = React.cloneElement(
                                            lastElement,
                                            { key: lastElement.key },
                                            [...existingChildren, wrappedInput]
                                        );
                                    }
                                } else {
                                    // Add non-column field
                                    acc.push(wrappedInput);
                                }
                                return acc;
                            }, [])
                        ) : (
                            <span />
                        )}
                    </div>,
                    register,
                    field
                )}
            </>
        );
    }

    public configureInput(inputEl, register, field) {
        let newInput = super.configureInput(inputEl, register, field);
        newInput = React.cloneElement(
            newInput,
            {
                style: {
                    margin: "0 !important",
                },
            },
            newInput.props.children
        );
        return newInput;
    }

    public PresenterComponent({ field }) {
        return <div>Group...</div>;
    }
}
