import React from "react";
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { signinUser } from "@rpforms/shared/build/actions/auth";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector((state: any) => state.auth.authenticated);
    const loginError = useSelector((state: any) => state.auth.error);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        dispatch(signinUser({ email, password }));
    };

    if (isAuthenticated) {
        history.push("/calendarevents");
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Bitte anmelden</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="stacked">E-Mail-Adresse</IonLabel>
                    <IonInput
                        value={email}
                        placeholder="E-Mail eingeben"
                        onIonChange={(e) => setEmail(e.detail.value)}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Passwort</IonLabel>
                    <IonInput
                        type="password"
                        value={password}
                        placeholder="Passwort eingeben"
                        onIonChange={(e) => setPassword(e.detail.value)}
                    />
                </IonItem>
                {loginError && <p style={{ color: "red" }}>{loginError.signin}</p>}
                <IonButton
                    expand="block"
                    onClick={handleLogin}
                    className="ion-margin-top"
                    disabled={!email || !password}
                >
                    Login
                </IonButton>
            </IonContent>
        </IonPage>
    );
};
