import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

type RouteConfig = {
    path: string;
    exact: boolean;
    component?: React.ComponentType<any>;
    render?: () => JSX.Element;
};

export const ProtectedRoute: React.FC<RouteConfig> = (props) => {
    const isAuthenticated = useSelector((state: any) => state.auth.authenticated);
    const { component: Component, render, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isAuthenticated ? (
                    Component ? <Component {...routeProps} /> : render?.()
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};
