import React, { useState, useEffect, useRef } from "react";

interface MoneyInputProps {
    value: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, valueInCents: number) => void;
    disabled?: boolean;
    name?: string;
    min?: number;
    style?: React.CSSProperties;
    className?: string;
}

export const MoneyInput = ({
    value,
    onChange,
    disabled,
    name,
    min,
    style,
    className,
}: MoneyInputProps) => {
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const cursorPositionRef = useRef<number | null>(null);

    const formatValue = (valueInCents: number): string => {
        const valueInEuros = valueInCents / 100;
        return new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
        }).format(valueInEuros);
    };

    const parseValue = (input: string): number => {
        // Remove currency symbol and spaces
        let numericString = input.replace(/[€\s]/g, "");
        // Handle German number format: convert to standard decimal
        // First, temporarily replace comma with a special character
        numericString = numericString.replace(",", "@");
        // Remove all dots (thousand separators)
        numericString = numericString.replace(/\./g, "");
        // Replace special character back with dot for decimal
        numericString = numericString.replace("@", ".");

        const valueInEuros = parseFloat(numericString);
        if (isNaN(valueInEuros)) {
            return 0;
        }
        return Math.round(valueInEuros * 100);
    };

    useEffect(() => {
        // On mount or when value prop changes, show formatted value
        setInputValue(formatValue(value));
    }, [value]);

    // Effect to restore cursor position after state update
    useEffect(() => {
        if (cursorPositionRef.current !== null && inputRef.current) {
            inputRef.current.setSelectionRange(
                cursorPositionRef.current,
                cursorPositionRef.current
            );
            cursorPositionRef.current = null;
        }
    }, [inputValue]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const cursorPosition = e.target.selectionStart;

        // Store cursor position before update
        if (cursorPosition !== null) {
            cursorPositionRef.current = cursorPosition;
        }

        // If the input contains a euro sign, parse it properly first
        if (newValue.includes("€")) {
            const valueInCents = parseValue(newValue);
            const valueInEuros = (valueInCents / 100).toString().replace(".", ",");
            setInputValue(valueInEuros);
            return;
        }

        // For raw input, handle normally
        let sanitizedValue = newValue.replace(/[^0-9.,-]/g, "");

        // Handle decimal places limitation
        const parts = sanitizedValue.split(/[.,]/);
        if (parts.length > 1) {
            // If we have decimal places, limit them to 2
            const integerPart = parts[0];
            const decimalPart = parts[1].slice(0, 2); // Take only first 2 decimal places
            sanitizedValue = `${integerPart},${decimalPart}`;
        }

        setInputValue(sanitizedValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const valueInCents = parseValue(inputValue);
            setInputValue(formatValue(valueInCents));
            if (onChange) {
                onChange(e as any, valueInCents);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const valueInCents = parseValue(inputValue);
        setInputValue(formatValue(valueInCents));
        if (onChange) {
            onChange(e, valueInCents);
        }
    };

    if (disabled) {
        return (
            <input
                ref={inputRef}
                style={{ ...style, textAlign: "right" }}
                className={`form-control ${className || ""}`}
                disabled
                value={formatValue(value)}
            />
        );
    }

    return (
        <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            name={name}
            min={min}
            className={`form-control ${className || ""}`}
            style={{ ...style, textAlign: "right" }}
        />
    );
};
